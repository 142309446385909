let timelineElements = [
    {
      id: 1,
      title: "Chief Strategy Officer",
      location: "GoProgs (Contract)",
      description: "Focus on long-term success and growth of the company and developing, implementing, and overseeing the company's strategic initiatives and plans. The primary focus is on ensuring the company's overall direction aligns with its mission, vision, and goals. \n\n\nEnsuring the company to adopt innovative solutions and enhance their product and services. Utilizing data, information and AI to get more targeted and focused analytics and forecasting the growth of the company. Ensuring that the entire workforce understands and aligns with the company's direction.",
      date: "Jul 2023 - Present",
      icon: "work"
    },
    {
      id: 2,
      title: "Director",
      location: "ConvSol",
      description: "Design, develop and deploying Rasa based closed domain Conversational AI assistants integrated with ChatGPT and llms for general and open domain responses.\n\n\n Domains:\n\n Banking\n\n Customer Support\n\n Healthcare\n\n Auto-industry\n\n Universities, etc\n\n\n Types:\n\n FAQ, Knowledge-base\n\n Appointment Scheduling\n\n Customer support\n Financial\n\n Database or information-retrieval based, etc\n\n\nFor consultation, quotation: info@convsol.com",
      date: "May 2022 - Present",
      icon: "work"
    },
    {
      id: 3,
      title: "Rasa Conversational AI Developer | Top Rated",
      location: "Fiverr/ Upwork (freelancing)",
      description:
        "Design and development of a Spanish chatbot with Custom Graph Sentiment Analysis Pipeline integrated with ChatGPT. \n\n\nFine-tuning of pysentimiento Spanish model and ChatGPT on customer's data. \n\n\nDesign and development of a travel agent chatbot integrated with ChatGPT.",
      // buttonText: "View Frontend Projects",
      // link: "/about",
      date: "May 2020 - Present",
      icon: "work",
    },
    {
      id: 4,
      title: "Data Analyst / React Django Developer",
      location: "ML Sense, UK",
      description:
        "Tableau | Data Analytics: \n\nCustomer Behaviour Analysis of UK based brands (IKEA, FootAsylum, etc) to increase sales. Furthermore, demographic and inventory analysis, best hours, days, weekdays and month for sales. \n\n\nReactJs & Django | Website Development:\n\nLogin, auth with token and user access web development for a major chain of brands for a custom dashboard built using JavaScript libraries.",
      // buttonText: "View Backend Projects",
      // link: "/about",
      date: "Nov 2021 - Feb 2022",
      icon: "work",
    },
    {
      id: 5,
      title: "Master of Science in Computer Science",
      location: "National University of Science and Technology, H-12, Islamabad",
      description:
        "Courses:\n\nOperation System\n\nAlgorithm Analysis\n\nMathematical Methods for Computing\n\nTheory of Automata\n\nMachine Learning\n\nDeep Learning\n\nNatural Language Processing\n\nComputer Vision",
      date: "Sep 2021 - Aug 2024",
      icon: "school",
    },
    {
      id: 6,
      title: "Data Science Executive",
      location: "HBL First Microfinance Bank Pvt. Ltd., Islamabad (Remote)",
      description:
      "Mainly used Python, R, and Power-BI for:\n\nText and Time-series Analysis\n\nATM transactions Failures\n\nTopic Classification\n\nAgent performance\n\nMajor causes of transaction failures\n\nAd-hoc reports\n\n\nPerformed SQL Analysis of:\n\nUser demographic\n\nConversation topics\n\n\nUtilized the chatbot API and databases to:\n\nAnnotate and improve conversations\n\n\nUsed Python and CronJob to:\n\nAutomate daily routine tasks\n\n\nBuilt Conversational Chatbots using RASA:\n\nDesigning, developing, and deploying\n\n\nAnnotating and improving conversations with:\n\nSpacy\n\nEnglish and Roman Urdu model training\n\nDeveloped Speech Recognition DL Model and deployed it using FastAPI\n\n\nML Model deployment using:\n\nDocker-compose\n\nKubernetes on Cloud and on-prem server\n\nEncryption and load balancing",
      // buttonText: "Company Website",
      // link: "/about",
      date: "Sept 2020 - Nov 2021",
      icon: "work",
    },
    {
      id: 7,
      title: "Data Scientist (Internship)",
      location: "The First MicroFinanceBank Ltd Pakistan (FMFB-P), Islamabad, Pakistan",
      description:
        "Used Python for:\n\nData Analysis\n\nVisualization\n\nbuilt dashboard using streamlit\n\n\nDevelopment for Psychometric Credit Scoring\n\n\nData Extraction using Facebook Graph API",
      // buttonText: "Course Certificate",
      // link: "https://google.com",
      date: "Feb 2020 - Apr 2020",
      icon: "work",
    },
    {
      id: 8,
      title: "Physics Subject Specialist",
      location: "tutoria.pk, Islamabad, Pakistan",
      description:
        "Developed Educational Content for High School and College Level Boards\n\nPhysics\n\nMath\n\n\n2d and 3d-Animation\n\nAnimatron and Blender.",
      // buttonText: "College Projects",
      // link: "/about",
      date: "Aug 2018 — Oct 2019",
      icon: "work",
    },
    
    {
      id: 9,
      title: "Bachelor of Science in Physics",
      location: "National University of Science and Technology, H-12, Islamabad",
      description:
        "Minor in Nanotechnology\n\nFYP on 2d-Mxene hybrid Thermoelectric Materials",
      date: "Sep 2014 - Aug 2018",
      icon: "school",
    },
  ];
  
  export default timelineElements;