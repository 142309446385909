import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Certified Rasa Developer",
          "ChatGPT and Langchain Developer",
          "Natural Language Processing Expert",
          "Cloud Native Developer",
        ],
        autoStart: true,
        loop: true,
        delay: 2,
        deleteSpeed: 2,
        // cursor: '$'
      }}
    />
  );
}

export default Type;
